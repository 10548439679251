<div class="container">
  <div class="row">
    <div class="col-12 d-flex justify-content-end align-items-center">
      <span class="p-1">Itens por página</span>

      <div class=" d-flex p-2">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{itensPerPage}}</button>
        <ul class="dropdown-menu">
<!--          <li><a class="dropdown-item" (click)="changeItensPerPage(5)">5</a></li>-->
          <li><a class="dropdown-item" (click)="changeItensPerPage(10)">10</a></li>
          <li><a class="dropdown-item" (click)="changeItensPerPage(15)">15</a></li>
          <li><a class="dropdown-item" (click)="changeItensPerPage(25)">25</a></li>
          <li><a class="dropdown-item" (click)="changeItensPerPage(50)">50</a></li>
        </ul>
      </div>

      <span class="p-3">{{currentIndex + 1}} - {{currentFinalIndex > totalItens ? totalItens:  currentFinalIndex}}  de {{length}}</span>
      <button type="button" class="btn actions" [disabled]="currentIndex <= 0 || itensPerPage >= totalItens" (click)="firstPage()"><mat-icon svgIcon="first-page"></mat-icon></button>
      <button type="button" class="btn actions" [disabled]="currentIndex <= 0 || itensPerPage >= totalItens" (click)="previewPage()"><mat-icon svgIcon="preview-page"></mat-icon></button>
      <button type="button" class="btn actions" [disabled]="currentFinalIndex >= totalItens"                 (click)="nextPage()"><mat-icon svgIcon="next-page"></mat-icon></button>
      <button type="button" class="btn actions" [disabled]="currentFinalIndex >= totalItens"                 (click)="lastPage()"><mat-icon svgIcon="last-page"></mat-icon></button>


    </div>
  </div>
</div>



