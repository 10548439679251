import {Storage} from 'aws-amplify';
import {Observable, catchError, from, throwError} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  public slicedFileName: string | undefined;

  putFile(file: File): Promise<any> {
    if (file) {
      let name = uuidv4();
      const dotIndex: number = file.name.indexOf('.');
      if (dotIndex !== -1) {
        this.slicedFileName = file.name.slice(dotIndex + 1);
      }
      return new Promise((resolve, reject) => {
        Storage.put(`${name}.${this.slicedFileName}`, file, {
          contentType: file.type,
        }).then(
          (sucess) => resolve(sucess),
          (err) => reject(err)
        );
      });
    } else {
      return Promise.resolve('');
    }
  }

  getFile(imageKey: string): Observable<string> {
    return from(Storage.get(imageKey)).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    );
  }


  putFileLocalHost(title, data: any) {
    localStorage.setItem(title, data)
  }
  getFileLocalHost(title) {
    return localStorage.getItem(title)
  }
}
