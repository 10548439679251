import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent {
  @Input('title') title: String | undefined;
  @Input('filter_activate') filter_activate: boolean | undefined;
  @Input('filters') filters: any;
  @Input('button_one') button_one: any;
  @Input('button_one_icon') button_one_icon: any;
  @Input('router_one') router_one: any;
  @Input('query_params_one') query_params_one: any;
  @Input('button_two') button_two: any;
  @Input('button_two_icon') button_two_icon: any;
  @Input('router_two') router_two: any;
  @Input('query_params_two') query_params_two: any;

  @Output() newItemEvent = new EventEmitter<any>();

  filter = "Escolher filtro"
  selectedFilters: any = []
  filterForm: FormGroup = new FormGroup({});

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    // private amplifyService: AmplifyService,
  ) {
    this.filterForm = this.formBuilder.group({
      value: new FormControl({value: '', disabled: true}, [Validators.required]),
    });
  }

  selectFilter(f: string) {
    this.filterForm.get('value')?.enable()
    this.filter = f
  }

  submitFilter() {
    const resp = this.filterForm.getRawValue();
    this.filterForm.get('value')?.disable()
    this.filterForm.get('value')?.reset()

    this.selectedFilters.push({
      filter: this.filter,
      value: resp['value']
    })
    this.filters.forEach(
      i => {
        if (i.value == this.filter) {
          i.enabled = false
        }
      }
    )
    this.filter = "Escolher filtro"
    this.addNewItem()

  }

  deleteFilter(obj: any) {
    this.filters.forEach(
      i => {
        if (i.value == obj.filter) {
          i.enabled = true
        }
      }
    )
    this.selectedFilters = this.selectedFilters.filter(option =>
      !(option.filter === obj.filter && option.value === obj.value)
    );
    this.addNewItem()

  }

  checkEmptyFiillters() {
    return this.filters.every(item => !item.enabled);
  }

  addNewItem() {
    this.newItemEvent.emit(this.selectedFilters);
  }
}
