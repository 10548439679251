import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UsersService} from "../../../pages/users/services/users.service";
import {StorageService} from "../services/storage.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  user = {
    name: '',
    img_link:"Sem Foto",
    picture:"Sem Foto",
  }
  constructor(
    private userService: UsersService,
    private storageService: StorageService
  ) {

  }

  ngAfterViewInit(): void {
    this.userService.getCurrentUser().then(
      (i: any) => {
        this.storageService.putFileLocalHost('user', JSON.stringify({name: i.data.getUser['name'], picture: i.data.getUser['picture']}))
        const  u = this.storageService.getFileLocalHost('user')
        // @ts-ignore
        this.user = JSON.parse(u)
        this.storageService.getFile(this.user.picture).subscribe(
          i =>{
            this.user.img_link = i
          }
        )

      }, err => {
        // Auth.signOut()
      }
    )
  }


}
