import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements  OnChanges {
  @Input('data') data: any[] = [];
  @Input('length') length: any = Number;
  @Output() finalData = new EventEmitter<any>();
  @Output() pageLenght = new EventEmitter<any>();

  itensPerPage = 10
  currentFinalIndex = 10
  currentIndex = 0
  totalItens = this.length

  ngOnChanges() {
    this.totalItens = this.length
    this.sliceByItensPerPage(0, this.currentFinalIndex)
    this.pageLenght.emit(this.itensPerPage)
  }

  changeItensPerPage(itensPerPage: number) {
    this.itensPerPage = itensPerPage
    this.currentFinalIndex = itensPerPage
    this.sliceByItensPerPage(0, itensPerPage)
    this.pageLenght.emit(this.itensPerPage)

  }

  firstPage() {
    this.sliceByItensPerPage(0, this.itensPerPage)
  }

  previewPage() {
    let currentIndex = this.currentIndex
    let itensPerPage = this.itensPerPage

    this.currentIndex = currentIndex - this.itensPerPage
    this.currentFinalIndex = this.currentFinalIndex - itensPerPage
    this.sliceByItensPerPage(this.currentIndex, this.currentFinalIndex)
  }

  nextPage() {
    this.currentIndex = this.currentFinalIndex
    this.currentFinalIndex = this.currentFinalIndex + this.itensPerPage
    this.sliceByItensPerPage(this.currentIndex, this.currentFinalIndex)
  }

  lastPage() {
    this.currentIndex = this.totalItens - (this.totalItens % this.itensPerPage)
    this.currentFinalIndex = this.currentIndex + this.itensPerPage
    this.sliceByItensPerPage(this.currentIndex, this.currentFinalIndex)
  }

  // aqui retorna o array tratado para se usado na lista
  sliceByItensPerPage(index, finalIndex) {
    this.finalData.emit(this.data.slice(index, finalIndex));
  }

}
