import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {catchError, map, Observable, of, Subject} from 'rxjs';
import { API } from 'aws-amplify';
// import {AmplifyService} from 'aws-amplify-angular';
import {Auth} from "aws-amplify";

import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoggedIn = new Subject<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.userLoggedIn.next(false);
  }


  autenticatedUser(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser({bypassCache: true}))
      .pipe(
        map(resp => {
          // @ts-ignore
          return true
        }),
        catchError(() => {
          Auth.signOut()
          this.router.navigate(['/login']).then();
          return of(false);
        })
      );
  }

}
