<div class="container menu " (mouseenter)="enterMenu()" (mouseleave)="leaveMenu()" #toggle>
  <header class="d-flex align-items-center ">
    <img class="m-1" src="assets/img/nav-bar-logo.svg" alt="">
    <img class="m-1 name" src="assets/img/nav-bar-name.svg" alt="">
  </header>
  <div class=" desktop">
    <div class="option "
         *ngFor="let item of menu">
      <div class="item"
           routerLink="{{item.link}}"
           (click)="leaveMenu()">
        <div class=" icon-">
          <mat-icon class=" {{ checkActive(router.url,item.link) ? 'on' : 'off'}}" svgIcon="{{item.icon}}"></mat-icon>
        </div>
        <div class=" name-">
          <span class=" {{ checkActive(router.url,item.link) ? 'on' : 'off'}}">{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

