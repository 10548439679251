<div class="container" style="min-height: 150px ">
  <div class="row h-100" style="max-width: none">
    <div class="col-7 d-flex align-items-center">
      <h1>{{title}}</h1>

      <button mat-flat-button class="btn" color="primary"  *ngIf="button_one" routerLink="{{router_one}}" [queryParams]="[query_params_one]">
        <span style="margin: 2px 5px">{{button_one}}</span>
        <mat-icon class="icon" svgIcon="{{button_one_icon}}" ></mat-icon>
      </button>

      <button mat-flat-button class="btn" *ngIf="button_two" routerLink="{{router_two}}" [queryParams]="[query_params_two]">
        <span style="margin: 2px 5px">{{button_two}}</span>
        <mat-icon class="icon"  svgIcon="{{button_two_icon}}"></mat-icon>
      </button>

    </div>

    <form *ngIf="filter_activate" [formGroup]="filterForm" class="col-5 filters">
      <div class="input-group mb-3 field w-100 ">
        <span class="input-group-text">
          <mat-icon class="eye deley" svgIcon="filter"></mat-icon>
        </span>

        <mat-form-field appearance="outline" color="primary"  style="width: calc(100% - 330px)">
          <mat-label>Filtro</mat-label>
          <input matInput formControlName="value">
        </mat-form-field>

        <button mat-button style="height: 55px" [disabled]="!this.filterForm.valid " (click)="submitFilter()">
          <mat-icon svgIcon="search" style="height: 55px"></mat-icon>
        </button>

        <mat-form-field appearance="outline" color="primary">
          <mat-label>Escolha o filtro</mat-label>
          <mat-select >
            <mat-option *ngFor="let f of filters" [value]="f.value" (click)="selectFilter(f.value)">
              <a *ngIf="f.enabled" class="dropdown-item">{{f.value}}</a>
            </mat-option>
            <mat-select *ngIf="checkEmptyFiillters()">
              <p style="padding: 0 10px">
                Sem mais filtros ;)

              </p>
            </mat-select>

          </mat-select>
        </mat-form-field>

      </div>
    </form>
    <div *ngIf="filter_activate" class="col-12 d-flex  align-items-center">
      <div class="chips" *ngFor="let sf of selectedFilters">
        <span>{{sf.value}}</span> <span>|</span> <span class="fillter">{{sf.filter}}</span>
        <div class="close" (click)="deleteFilter(sf)">
          <mat-icon class="icon" svgIcon="close"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

