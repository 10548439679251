import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'open-delivery';
  routeLogin =  true

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
    public router: Router) {
    this.routeLogin = window.location.href.includes('login')

    matIconRegistry.addSvgIcon(
      'eye-open',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-open.svg')
    );
    matIconRegistry.addSvgIcon(
      'eye-closed',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-closed.svg')
    );
    matIconRegistry.addSvgIcon(
      'home',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg')
    );
    matIconRegistry.addSvgIcon(
      'chart',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chart.svg')
    );
    matIconRegistry.addSvgIcon(
      'delivery',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delivery.svg')
    );
    matIconRegistry.addSvgIcon(
      'route',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/route.svg')
    );
    matIconRegistry.addSvgIcon(
      'message',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/message.svg')
    );
    matIconRegistry.addSvgIcon(
      'vehicle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicle.svg')
    );
    matIconRegistry.addSvgIcon(
      'users',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/users.svg')
    );
    matIconRegistry.addSvgIcon(
      'user',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg')
    );
    matIconRegistry.addSvgIcon(
      'configuration',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/configuration.svg')
    );
    matIconRegistry.addSvgIcon(
      'notification',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification.svg')
    );
    matIconRegistry.addSvgIcon(
      'filter',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg')
    );
    matIconRegistry.addSvgIcon(
      'search',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
    );
    matIconRegistry.addSvgIcon(
      'close',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    );
    matIconRegistry.addSvgIcon(
      'first-page',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/first-page.svg')
    );
    matIconRegistry.addSvgIcon(
      'last-page',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/last-page.svg')
    );
    matIconRegistry.addSvgIcon(
      'next-page',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/next-page.svg')
    );
    matIconRegistry.addSvgIcon(
      'preview-page',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/preview-page.svg')
    );
    matIconRegistry.addSvgIcon(
      'point',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/point.svg')
    );
    matIconRegistry.addSvgIcon(
      'menu',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu.svg')
    );
    matIconRegistry.addSvgIcon(
      'add-user',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-user.svg')
    );
    matIconRegistry.addSvgIcon(
      'car',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/car.svg')
    );
    matIconRegistry.addSvgIcon(
      'motocycle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/motocycle.svg')
    );
    matIconRegistry.addSvgIcon(
      'ambulance',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/ambulance.svg')
    );
    matIconRegistry.addSvgIcon(
      'bus',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/bus.svg')
    );
    matIconRegistry.addSvgIcon(
      'car',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/car.svg')
    );
    matIconRegistry.addSvgIcon(
      'picape',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/picape.svg')
    );
    matIconRegistry.addSvgIcon(
      'truck',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/truck.svg')
    );
    matIconRegistry.addSvgIcon(
      'van',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vehicles/van.svg')
    );
    matIconRegistry.addSvgIcon(
      'import-delivery',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/import-delivery.svg')
    );
    matIconRegistry.addSvgIcon(
      'create-delivery',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/create-delivery.svg')
    );
    matIconRegistry.addSvgIcon(
      'add',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    matIconRegistry.addSvgIcon(
      'store',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/store.svg')
    );
    matIconRegistry.addSvgIcon(
      'add-store',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-store.svg')
    );
    matIconRegistry.addSvgIcon(
      'arrow-back',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-back.svg')
    );
  }


  protected readonly window = window;
}
