<div class="container">
  <div class="row">
    <div class="col-12 d-flex justify-content-center align-items-center flex-column h-100">
      <img id="image" src="assets/img/download-image.png" alt="" style="min-height: 200px">
      <div class="loading-bar">
        <div class="progress-bar "></div>

      </div>
    </div>
  </div>
</div>
