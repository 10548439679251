import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  @ViewChild('toggle') toggleItem: any;
  constructor(
    public router : Router,
  ) {
  }
  menu = [
    // {
    //   name: "Dashboard",
    //   icon: "chart",
    //   link: "dashboard"
    // },
    {
      name: "Entregas",
      icon: "delivery",
      link: "delivery"
    },
    {
      name: "Rotas",
      icon: "route",
      link: "route"
    },
    // {
    //   name: "Mensagens",
    //   icon: "message",
    //   link: "message"
    // },
    {
      name: "Veículos",
      icon: "vehicle",
      link: "vehicle"
    },
    {
      name: "Empresas",
      icon: "store",
      link: "companies"
    },
    {
      name: "Usuários",
      icon: "users",
      link: "users"
    },
  ]
  open = false
  enterMenu() {
    this.toggleItem.nativeElement.classList.add('menu-')
  }

  leaveMenu() {
    this.toggleItem.nativeElement.classList.remove('menu-')
  }
  checkActive(url : any, link : any){
    return url.includes(link)
  }
}

