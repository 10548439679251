export const awsmobileDev = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:44c5e261-0bc2-4c77-9de7-584988aff8ee",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_nKc08Rs5a",
  "aws_user_pools_web_client_id": "uce5irohbgompgvn7rubr3nln",
  "oauth": {},
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": [
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS"
    ]
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://ejotyk4xevcphlevddnx4i4wgm.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_user_files_s3_bucket": "open-delivery-files",
  "aws_user_files_s3_bucket_region": "us-east-1"
};
