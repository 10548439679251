<div class="home" *ngIf="!window.location.href.includes('login') && !window.location.href.includes('track') ">
  <app-nav-bar></app-nav-bar>
  <div class="content d-flex flex-column ">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="window.location.href.includes('login')  || window.location.href.includes('track') ">
  <router-outlet></router-outlet>
</div>
