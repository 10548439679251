import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import { HeaderComponent } from './header/header.component';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import {ReactiveFormsModule} from "@angular/forms";
import { PaginatorComponent } from './paginator/paginator.component';
import { LoadingComponent } from './loading/loading.component';
import { EmptyComponent } from './empty/empty.component';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    NavBarComponent,
    HeaderComponent,
    FilterHeaderComponent,
    PaginatorComponent,
    LoadingComponent,
    EmptyComponent,
  ],
    exports: [
        NavBarComponent,
        HeaderComponent,
        FilterHeaderComponent,
        PaginatorComponent,
        LoadingComponent,
        EmptyComponent
    ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule
  ]
})
export class SharedModule { }
