/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $document: String!
  $email: String!
  $name: String!
  $type: USERTYPE!
  $contact: String
  $picture: String
) {
  createUser(
    document: $document
    email: $email
    name: $name
    type: $type
    contact: $contact
    picture: $picture
  ) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $sub: String!
  $document: String
  $available: Boolean
  $name: String
  $type: USERTYPE
  $contact: String
  $picture: String
) {
  updateUser(
    sub: $sub
    document: $document
    available: $available
    name: $name
    type: $type
    contact: $contact
    picture: $picture
  ) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const disableUser = /* GraphQL */ `mutation DisableUser($sub: String!, $enable: Boolean) {
  disableUser(sub: $sub, enable: $enable) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DisableUserMutationVariables,
  APITypes.DisableUserMutation
>;
export const atualizeVehicles = /* GraphQL */ `mutation AtualizeVehicles($url: String, $token: String) {
  atualizeVehicles(url: $url, token: $token) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AtualizeVehiclesMutationVariables,
  APITypes.AtualizeVehiclesMutation
>;
export const importDeliveries = /* GraphQL */ `mutation ImportDeliveries($data: [Delivery]) {
  importDeliveries(data: $data) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ImportDeliveriesMutationVariables,
  APITypes.ImportDeliveriesMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $address: Address
  $customer_name: String
  $customer_number: String!
  $fone: String
  $picture: String
  $phone_for_link: String
  $contact_form: CONTACT_TYPE
) {
  createCompany(
    address: $address
    customer_name: $customer_name
    customer_number: $customer_number
    fone: $fone
    picture: $picture
    phone_for_link: $phone_for_link
    contact_form: $contact_form
  ) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $address: Address
  $customer_name: String
  $customer_number: String!
  $fone: String
  $picture: String
  $phone_for_link: String
  $contact_form: CONTACT_TYPE
) {
  updateCompany(
    address: $address
    customer_name: $customer_name
    customer_number: $customer_number
    fone: $fone
    picture: $picture
    phone_for_link: $phone_for_link
    contact_form: $contact_form
  ) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const createDeliveryRoute = /* GraphQL */ `mutation CreateDeliveryRoute(
  $name: String
  $operator: String
  $priority: String
  $date: String
  $recorent: Boolean
  $deliveries: [Delivery]
) {
  createDeliveryRoute(
    name: $name
    operator: $operator
    priority: $priority
    date: $date
    recorent: $recorent
    deliveries: $deliveries
  ) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeliveryRouteMutationVariables,
  APITypes.CreateDeliveryRouteMutation
>;
export const updateStatusRoute = /* GraphQL */ `mutation UpdateStatusRoute($route: String, $delivery: String, $status: STATUS) {
  updateStatusRoute(route: $route, delivery: $delivery, status: $status) {
    statusCode
    body
    item
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStatusRouteMutationVariables,
  APITypes.UpdateStatusRouteMutation
>;
