import {Injectable, OnChanges, SimpleChanges} from '@angular/core';
import {API, graphqlOperation} from "aws-amplify";
import {createUser, updateUser, disableUser} from "../../../../graphql/mutations";
import {newUser} from "../models/users";
import {getUser, listUser} from "../../../../graphql/queries";

@Injectable({
  providedIn: 'root'
})
export class UsersService implements OnChanges{
  users = []
  constructor() {}

  createNewUser(data: newUser): Promise<newUser> {
    return new Promise<any>((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(createUser, data)));
      reject('error');
    });
  }
  getCurrentUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(getUser)));
      reject('error');
    });
  }
  
  getUser(id : string ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(getUser, {'sub': id})));
      reject('error');
    });
  }

  editUser(data: { sub: any; available: boolean }): Promise<newUser> {
    return new Promise<any>((resolve, reject) => {
      resolve(API.graphql(graphqlOperation(updateUser, data)));
      reject('error');
    });
  }
  ListUser(data:any): Promise<newUser> {
    return new Promise<any>((resolve, reject) => {
      resolve( API.graphql(graphqlOperation(listUser, data)));
      reject('error');
    });
  }

  disableUser(data:any): Promise<newUser> {
    return new Promise<any>((resolve, reject) => {
      resolve( API.graphql(graphqlOperation(disableUser, data)));
      reject('error');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
