import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {AuthGuard} from "./core/auth.guard";

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'delivery',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/deliveries/deliveries.module').then(m => m.DeliveriesModule)
  },
  {
    path: 'route',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/routes/routes.module').then(m => m.RoutesModule)
  },
  {
    path: 'message',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'vehicle',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule)
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./core/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'track',
    loadChildren: () => import('./core/track/track.module').then(m => m.TrackModule)
  },
  {
    path: '**',
    redirectTo:'delivery',
    pathMatch: "full"
  },
  {
    path: '**',
    redirectTo:'login',
    pathMatch: "full"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
