import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {AuthService} from "./authService";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.autenticatedUser()
      .pipe(
        tap(resp => {
          if (!resp) {
            this.router.navigate(['/login'])
            return false
          }
          else{
            return true
          }
        })
      )
  }
}

