/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($sub: String) {
  getUser(sub: $sub) {
    document
    name
    contact
    type
    userCreateDate
    userLastModifiedDate
    enabled
    available
    userStatus
    picture
    email
    sub
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getCompanies = /* GraphQL */ `query GetCompanies($customer_number: String) {
  getCompanies(customer_number: $customer_number) {
    address {
      city
      complement
      country
      district
      number
      state
      street
      zip_code
      __typename
    }
    customer_name
    customer_number
    picture
    fone
    phone_for_link
    contact_form
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompaniesQueryVariables,
  APITypes.GetCompaniesQuery
>;
export const listUser = /* GraphQL */ `query ListUser($limit: Int!, $LastEvaluatedKey: String, $filter: User) {
  listUser(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
    filter: $filter
  ) {
    items {
      document
      name
      contact
      type
      userCreateDate
      userLastModifiedDate
      enabled
      available
      userStatus
      picture
      email
      sub
      __typename
    }
    LastEvaluatedKey
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUserQueryVariables, APITypes.ListUserQuery>;
export const listVehicles = /* GraphQL */ `query ListVehicles($limit: Int!, $LastEvaluatedKey: String, $filter: Vehicles) {
  listVehicles(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
    filter: $filter
  ) {
    items {
      gprs
      plate
      name
      battery
      type
      driver
      ignition
      date
      gps
      id
      block
      location
      lng
      lat
      last_update
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVehiclesQueryVariables,
  APITypes.ListVehiclesQuery
>;
export const listDeliveriesOpen = /* GraphQL */ `query ListDeliveriesOpen($limit: Int!, $LastEvaluatedKey: String) {
  listDeliveriesOpen(limit: $limit, LastEvaluatedKey: $LastEvaluatedKey) {
    items {
      accessKey
      priority
      status
      route
      from {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        company_name
        employer_number
        trading_name
        fone
        __typename
      }
      creation_date
      to {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        customer_name
        customer_number
        fone
        __typename
      }
      position {
        lat
        lng
        __typename
      }
      _package {
        pesoB
        pesoL
        volumes {
          cProd
          esp
          qCom
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveriesOpenQueryVariables,
  APITypes.ListDeliveriesOpenQuery
>;
export const listDeliveriesHandedOut = /* GraphQL */ `query ListDeliveriesHandedOut($limit: Int!, $LastEvaluatedKey: String) {
  listDeliveriesHandedOut(limit: $limit, LastEvaluatedKey: $LastEvaluatedKey) {
    items {
      accessKey
      priority
      status
      route
      from {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        company_name
        employer_number
        trading_name
        fone
        __typename
      }
      creation_date
      to {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        customer_name
        customer_number
        fone
        __typename
      }
      position {
        lat
        lng
        __typename
      }
      _package {
        pesoB
        pesoL
        volumes {
          cProd
          esp
          qCom
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveriesHandedOutQueryVariables,
  APITypes.ListDeliveriesHandedOutQuery
>;
export const listDeliveriesCanceled = /* GraphQL */ `query ListDeliveriesCanceled($limit: Int!, $LastEvaluatedKey: String) {
  listDeliveriesCanceled(limit: $limit, LastEvaluatedKey: $LastEvaluatedKey) {
    items {
      accessKey
      priority
      status
      route
      from {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        company_name
        employer_number
        trading_name
        fone
        __typename
      }
      creation_date
      to {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        customer_name
        customer_number
        fone
        __typename
      }
      position {
        lat
        lng
        __typename
      }
      _package {
        pesoB
        pesoL
        volumes {
          cProd
          esp
          qCom
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveriesCanceledQueryVariables,
  APITypes.ListDeliveriesCanceledQuery
>;
export const listDeliveriesWithoutRoutes = /* GraphQL */ `query ListDeliveriesWithoutRoutes($limit: Int!, $LastEvaluatedKey: String) {
  listDeliveriesWithoutRoutes(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
  ) {
    items {
      accessKey
      priority
      status
      route
      from {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        company_name
        employer_number
        trading_name
        fone
        __typename
      }
      creation_date
      to {
        address {
          city
          complement
          country
          district
          number
          state
          street
          zip_code
          __typename
        }
        customer_name
        customer_number
        fone
        __typename
      }
      position {
        lat
        lng
        __typename
      }
      _package {
        pesoB
        pesoL
        volumes {
          cProd
          esp
          qCom
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveriesWithoutRoutesQueryVariables,
  APITypes.ListDeliveriesWithoutRoutesQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies($limit: Int!, $LastEvaluatedKey: String, $filter: Company) {
  listCompanies(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
    filter: $filter
  ) {
    items {
      address {
        city
        complement
        country
        district
        number
        state
        street
        zip_code
        __typename
      }
      customer_name
      customer_number
      picture
      fone
      phone_for_link
      contact_form
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const checkCompany = /* GraphQL */ `query CheckCompany($customer_number: String) {
  checkCompany(customer_number: $customer_number) {
    customer_number
    canCreate
    phone_for_link
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckCompanyQueryVariables,
  APITypes.CheckCompanyQuery
>;
export const listRoutes = /* GraphQL */ `query ListRoutes(
  $limit: Int!
  $LastEvaluatedKey: String
  $filter: RouteFilter
) {
  listRoutes(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
    filter: $filter
  ) {
    items {
      name
      operator
      priority
      creation_date
      recorent
      deliveries {
        accessKey
        priority
        status
        route
        from {
          address {
            city
            complement
            country
            district
            number
            state
            street
            zip_code
            __typename
          }
          company_name
          employer_number
          trading_name
          fone
          __typename
        }
        creation_date
        to {
          address {
            city
            complement
            country
            district
            number
            state
            street
            zip_code
            __typename
          }
          customer_name
          customer_number
          fone
          __typename
        }
        position {
          lat
          lng
          __typename
        }
        _package {
          pesoB
          pesoL
          volumes {
            cProd
            esp
            qCom
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRoutesQueryVariables,
  APITypes.ListRoutesQuery
>;
export const listDeliveryRoutesByUser = /* GraphQL */ `query ListDeliveryRoutesByUser(
  $limit: Int!
  $LastEvaluatedKey: String
  $operator: String
) {
  listDeliveryRoutesByUser(
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
    operator: $operator
  ) {
    items {
      name
      operator
      priority
      creation_date
      recorent
      deliveries {
        accessKey
        priority
        status
        route
        from {
          address {
            city
            complement
            country
            district
            number
            state
            street
            zip_code
            __typename
          }
          company_name
          employer_number
          trading_name
          fone
          __typename
        }
        creation_date
        to {
          address {
            city
            complement
            country
            district
            number
            state
            street
            zip_code
            __typename
          }
          customer_name
          customer_number
          fone
          __typename
        }
        position {
          lat
          lng
          __typename
        }
        _package {
          pesoB
          pesoL
          volumes {
            cProd
            esp
            qCom
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    LastEvaluatedKey
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveryRoutesByUserQueryVariables,
  APITypes.ListDeliveryRoutesByUserQuery
>;
