<div class="main">
<header class="d-flex justify-content-end align-items-center">
  <button type="button" class="btn  m-1">
    <mat-icon class="eye deley" color="warn" svgIcon="notification"></mat-icon>
  </button>
  <button  type="button" class="btn  m-1">
    <mat-icon class="eye deley" svgIcon="configuration"></mat-icon>
  </button>
  <span class="m-3 " style="min-width: 170px">{{user.name}}</span>
  <div class="image-user m-3">
    <img src="{{user.img_link}}" alt="">
  </div>
</header>
</div>
